@font-face {
    font-family: 'Kino';
    src: url('../fonts/Kino-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/Proxima Nova Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/Proxima Nova Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/Proxima Nova Semibold.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
